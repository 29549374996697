/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-17 18:35:46
 * @Module: 广告详情
 */
<template>
  <div class="adDetail-box">
    <div class="adDetail-content">
      <div v-html="detailData.content"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      loading: true,
      detailData: null
    };
  },
  mounted () {
    const { slug } = this.$route.query
    this._get_detail(slug)
  },
  methods: {
    async _get_detail (slug) {
      this.loading = true
      const { status, info, data } = await this.$api.getHomeBannersDetail(slug)
      this.loading = false
      if (status == 200) {
        this.detailData = data
      } else {
        this.$message.error(info)
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.adDetail-content {
  margin: 0 auto;
  width: 660px;
  min-height: 100vh;
}
</style>